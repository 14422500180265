<template>
  <div class="page">
    <el-form :model="configForm" :rules="configRule">
      <el-form-item label="评论设置：">
        <el-radio-group v-model="configForm.status">
          <el-radio
            :key="item.id"
            :label="item.id"
            v-for="item in commentEnableArr"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
    </el-form>
    <fixed-action-bar>
      <el-button :loading="saveLoading" @click="save()" type="primary"
        >保存</el-button
      >
    </fixed-action-bar>
  </div>
</template>

<script>
import { getAllCommentConfig, setAllCommentConfig } from '../api/global-comment'
import FixedActionBar from '../../../base/layout/FixedActionBar'

export default {
  components: { FixedActionBar },
  data() {
    return {
      commentEnableArr: [
        { name: '允许评论', id: 1 },
        { name: '不允许评论', id: 0 },
      ],
      configForm: {
        status: 1,
      },
      configRule: {},
      saveLoading: false,
    }
  },
  created() {
    getAllCommentConfig()
      .then(({ data, msg }) => {
        this.configForm = data
      })
      .catch(() => {})
  },
  methods: {
    save() {
      if (this.saveLoading) return

      this.saveloading = true
      setAllCommentConfig({
        // config: JSON.stringify(this.configForm),
        config: this.configForm,
      })
        .then(({ data, msg }) => {
          this.$message.success(msg)
          this.saveloading = false
        })
        .catch(() => {
          this.saveloading = false
        })
    },
  },
}
</script>
